import { Checkbox, Table } from "antd";

import NamePlate from "./Nameplate";
import TechnicalData from "./TechnicalData";
import Documentation from "./Documentation";

function Tables() {
  const dataSource = [
    {
      key: "1",
      name: "Nameplate",
      value: "Submodel",
    },
    {
      key: "2",
      name: "Technical Data",
      value: "Submodel",
    },

    {
      key: "3",
      name: "Documentation",
      value: "Submodel",
    },
  ];

  const columns = [
    {
      dataIndex: "name",
      key: "name",
    },
    {
      dataIndex: "value",
      key: "value",
    },
    {
      dataIndex: "",
      key: "",
      width: 200,
    },
    {
      dataIndex: "",
      key: "",
      width: 10,
      render: () => <Checkbox />,
    },
  ];

  return (
    <Table
      showHeader={false}
      size="small"
      bordered
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => <NamePlate />,
      }}
    />
  );
}

export default Tables;
