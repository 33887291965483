import "./styles.scss";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
function PageHeader(props: any) {
  const navigate = useNavigate();
  return (
    <div className="AuthPageHeader">
      <div className="AuthPageHeader-back" onClick={() => navigate(-1)}>
        <IoChevronBackOutline />
      </div>
      <div>
        <div className="AuthPageHeader-text1">{props?.title}</div>
        <div className="AuthPageHeader-text2">{props.breadcum}</div>
      </div>
      <div style={{ flex: 1 }} />
      <div className="AuthPageHeader-children">{props.children}</div>
    </div>
  );
}

export default PageHeader;
