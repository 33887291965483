import { Button, Form, Input, Modal } from "antd";
import { Table } from "react-bootstrap";
const SpecificAssetIdModal = (props: any) => {
  return (
    <Modal
      title="Specific Asset ID"
      open={props?.visible}
      width={700}
      onCancel={() => props?.onClose()}
      onOk={() => props?.onClose()}
    >
      <Table bordered>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props?.fields?.map(
            ({ key, name, ...restField }: any, index: any) => (
              <tr key={index}>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "assetId_key"]}
                  >
                    <Input
                      bordered={false}
                      size="large"
                      placeholder="Enter Asset ID Key"
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "assetId_value"]}
                  >
                    <Input
                      bordered={false}
                      size="large"
                      placeholder="Enter Asset ID Value"
                    />
                  </Form.Item>
                </td>
                <td style={{ textAlign: "center", width: 50 }}>
                  {index === props?.fields.length - 1 ? (
                    <Button
                      type="primary"
                      ghost
                      block
                      size="small"
                      onClick={props?.add}
                    >
                      Add +
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      danger
                      block
                      onClick={() => props.remove(index)}
                    >
                      Remove
                    </Button>
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </Modal>
  );
};

export default SpecificAssetIdModal;
