import { Button, Card, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Col, Row, Table } from "react-bootstrap";
import { IoIosRemoveCircleOutline } from "react-icons/io";

const Property = (props: any) => {
  const readonly = props?.readonly ? props?.readonly : false;
  return (
    <>
      {props?.fields?.map(({ key, name, ...restField }: any) => (
        <Card
          size="small"
          title={"Property"}
          extra={
            !readonly && (
              <Button size="small" danger onClick={() => props?.remove(name)}>
                Remove
              </Button>
            )
          }
          className="mb-4"
        >
          <Table key={key} bordered>
            <thead>
              <tr>
                <th>ID Short</th>
                <th>Display Name</th>
                <th>Semantic ID</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "id_short"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter ID Short" },
                    ]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter ID Short"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "display_name"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter Display Name" },
                    ]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter name"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "semantic_id"]}
                    rules={[
                      { 
                        // required: true, 
                        message: "Please enter Semantic ID" },
                    ]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter Semantic ID"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "description"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter description" },
                    ]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter description"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered={true}>
            <thead>
              <tr>
                <th>Value</th>
                {/* <th>Value Type</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 10 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    label="Value"
                    name={[name, "value"]}
                    rules={[{ 
                      // required: true,
                       message: "Please enter value" }]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter value"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                {/* <td>
                  {" "}
                  <Form.Item
                    noStyle
                    {...restField}
                    label="Value"
                    name={[name, "content_type"]}
                    rules={[{ required: true, message: "Please enter value type" }]}
                  >
                  <Input
                    bordered={false}
                    size="large"
                    readOnly={readonly}
                    placeholder="Enter value Type"
                    style={{ padding: 10 }}
                  />
                  </Form.Item>
                </td> */}
                {/* <td style={{ width: 10 }}>
                  <Button>Add</Button>
                </td> */}
              </tr>
            </tbody>
          </Table>
        </Card>
      ))}
    </>
  );
};

export default Property;
