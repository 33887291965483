import { Button, Card, Form, Input, Popover } from "antd";
import { Table } from "react-bootstrap";
import Property from "./property";
import MultiLanguageProperty from "./multiLanguageProperty";
import File from "./file";
import Range from "./range";
import ReferenceElement from "./referenceElement";
import RelationshipElement from "./relationshipElement";
import { useState } from "react";
import Sub from "./sub";

const componentMapping: any = {
  Property: Property,
  MultiLanguageProperty: MultiLanguageProperty,
  File: File,
  Range: Range,
  ReferenceElement: ReferenceElement,
  RelationshipElement: RelationshipElement,
  SubModelElementCollection: Sub,
  // AnnotatedRelationshipElement: AnnotatedRelationshipElement,
  // Entity: Entity,
};

interface SubModelElement {
  type: keyof typeof componentMapping;
  key: number;
  fieldKey: number;
}

const SubmodelElementCollection = (props: any) => {
  let SubmodelElementCollection: any = [];
  let pagetype = props?.type
  if (pagetype === "edit" || props?.readonly) {
    if (props?.submodelFlag) {
      if(props?.submodelElementCollection?.length){
        SubmodelElementCollection = props?.submodelElementCollection;
      }
      else{
        pagetype = "";
        SubmodelElementCollection = null;
      }
     
    } else {
      // const storedElements: any = localStorage.getItem("submodelElements");
      // const parsedElements = JSON.parse(storedElements);
      
      if (props?.sub?.length) {
        SubmodelElementCollection = props?.sub;
      }
      else{
        pagetype = "";
        SubmodelElementCollection = null;
      }
    }
  }
  const subModelValueCount: any = () => {
    if (pagetype === "edit" || props?.readonly) {
      return 1;
    }
    return 1;
  };
  const rtyu: any = () => {
    if (props?.readonly) {
      for (let i = 0; i < props?.fields?.length; i++) {
        const { name } = props.fields[i];
        const currentElementValue = props?.form?.getFieldValue([
          "Elements",
          name,
        ]);

        // console.log(`Field ${i}:`, currentElementValue);

        if (currentElementValue?.SubmodelElementCollection) {
          return "SubModelElementCollection";
        } else if (currentElementValue?.first) {
          return "RelationshipElement";
        } else if (currentElementValue?.min) {
          return "Range";
        } else if (currentElementValue?.multilanguage_property?.length) {
          return "MultiLanguageProperty";
        } else if (currentElementValue?.content_type) {
          return "File";
        } else if (Array.isArray(currentElementValue?.value)) {
          return "ReferenceElement";
        }
      }
      return "Property"; // Default if none match
    }
    return null; // If readonly is false
  };
  const [rows, setRows] = useState<any[][]>(
    props.fields.map(() =>
      Array(subModelValueCount() || 1)
        .fill(null)
        .map((_, index) => ({
          key: index,
          name: "",
          value: "",
        }))
    )
  );
  const [submodelComponent, setSubmodelComponent] = useState<any>(
    pagetype === "edit" || props?.readonly
      ? SubmodelElementCollection?.[0]?.type
      : rtyu()
  );
  const readonly = props?.readonly ? props?.readonly : false;
  const [languageCount, setLanguageCount] = useState<number>(1);
  const [referenceCount, setReferenceCount] = useState<number>(1);
  const [relationCount, setRelationCount] = useState<number>(1);
  const [subModelElementsCollection, setSubModelElementsCollection] = useState<
    SubModelElement[]
  >([]);
  const [subModelElementCollection, setSubmodelElementCollection] =
    useState<boolean>(false);
  const [subModelElements, setSubModelElements] = useState<SubModelElement[]>(
    []
  );

  const items = Object.keys(
    componentMapping
  ) as (keyof typeof componentMapping)[];

  const handlePopoverItemClick = (
    item: keyof typeof componentMapping,
    add: any,
    fields: any[],
    type: boolean
  ) => {
    add();
    if (pagetype === "edit") {
      let newFieldKey;
      if (SubmodelElementCollection === undefined) {
        newFieldKey = 1;
      } else {
        const lastElement =
          SubmodelElementCollection[SubmodelElementCollection?.length - 1];
        newFieldKey = lastElement ? lastElement.fieldKey + 1 : 0;
      }
      const newElement = {
        type: item,
        key: Date.now(),
        fieldKey: newFieldKey,
      };
      if (SubmodelElementCollection) {
        SubmodelElementCollection.push(newElement);
      } else {
        SubmodelElementCollection = [newElement];
      }
    } else {
      if (type) {
        setSubModelElements((prevElements) => [
          ...prevElements,
          { type: item, key: Date.now(), fieldKey: fields.length },
        ]);
      } else {
        setSubModelElementsCollection((prevElements) => [
          ...prevElements,
          { type: item, key: Date.now(), fieldKey: fields.length },
        ]);
      }
    }

    setTimeout(() => {
      const formElement = document.querySelector(".ant-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 0);
  };
  return (
    <>
      {props?.fields?.map(
        ({ key, name, ...restField }: any, fieldIndex: any) => (
          <Card
            key={key}
            size="small"
            title={"Submodel Element Collection"}
            extra={
              !readonly && (
                <Button size="small" danger onClick={() => props?.remove(name)}>
                  Remove
                </Button>
              )
            }
            className="mb-4"
          >
            <Table key={key} bordered>
              <thead>
                <tr>
                  <th>ID Short</th>
                  <th>Display Name</th>
                  <th>Semantic ID</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "id_short"]}
                      rules={[
                        {
                          // required: true,
                          message: "Please enter ID Short",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter ID Short"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "display_name"]}
                      rules={[
                        {
                          // required: true,
                          message: "Please enter Display Name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter name"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "semantic_id"]}
                      rules={[
                        {
                          // required: true,
                          message: "Please enter Semantic ID",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter Semantic ID"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "description"]}
                      rules={[
                        {
                          // required: true,
                          message: "Please enter description",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter description"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </Table>

            {rows[fieldIndex]?.map((row, rowIndex) => (
                  <Form.List
                    {...restField}
                    name={[name, "SubmodelElementCollection", rowIndex]}
                  >
                    {(fields, { add, remove }) => (
                      <Card
                        extra={
                          <div>
                            <Popover
                              arrow={false}
                              placement={"bottom"}
                              content={items.map((item: any, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    handlePopoverItemClick(
                                      item,
                                      add,
                                      fields,
                                      false
                                    );
                                  }}
                                  className="subModelScreenFrom-item"
                                >
                                  {item}
                                </div>
                              ))}
                              trigger="click"
                            >
                              {!props?.readonly && (
                                <Button type="primary" ghost>
                                  Add Submodel Element
                                </Button>
                              )}
                            </Popover>
                          </div>
                        }
                      >
                        {/* {submodelComponent} */}
                        {(SubmodelElementCollection?.length ?? 0) !==
                          (fields?.length ?? 0) &&
                        (pagetype === "edit" || props?.readonly)
                          ? fields?.slice(0, -1)?.map((field: any, index) => {
                              let Component;
                              let element: any;
                              if (props?.readonly || pagetype === "edit") {
                                element = {
                                  type: SubmodelElementCollection?.[
                                    field?.fieldKey
                                  ]?.type,
                                  key: Date.now(),
                                  fieldKey: 0,
                                };
                                if (
                                  SubmodelElementCollection?.[field?.fieldKey]
                                    ?.type === "SubmodelElementCollection"
                                ) {
                                  Component =
                                    componentMapping[
                                      "SubModelElementCollection"
                                    ];
                                } else {
                                  Component =
                                    componentMapping[
                                      SubmodelElementCollection?.[
                                        field?.fieldKey
                                      ]?.type
                                    ];
                                }
                              } else {
                                element = subModelElementsCollection.find(
                                  (el) => el.fieldKey === field.name
                                );
                                if (!element) return null;
                                Component = componentMapping[element?.type];
                              }

                              return (
                                <Component
                                  key={element.key}
                                  fields={[field]}
                                  languageCount={languageCount}
                                  referenceCount={referenceCount}
                                  relationCount={relationCount}
                                  form={props?.form}
                                  readonly={readonly && readonly}
                                  type={pagetype === "edit" && pagetype}
                                  submodelElementCollection={
                                    (pagetype === "edit" ||
                                      props?.readonly) &&
                                    SubmodelElementCollection?.[field?.fieldKey]
                                      ?.value
                                  }
                                  submodelFlag={
                                    (pagetype === "edit" ||
                                      props?.readonly) &&
                                    true
                                  }
                                  remove={(name: any) => {
                                    remove(name);
                                    setSubModelElementsCollection(
                                      (prevElements) =>
                                        prevElements.filter(
                                          (el) => el.key !== element.key
                                        )
                                    );
                                  }}
                                />
                              );
                            })
                          : fields?.map((field: any, index) => {
                              let Component;
                              let element: any;
                              if (props?.readonly || pagetype === "edit") {
                                element = {
                                  type: SubmodelElementCollection?.[
                                    field?.fieldKey
                                  ]?.type,
                                  key: Date.now(),
                                  fieldKey: 0,
                                };

                                if (
                                  SubmodelElementCollection?.[field?.fieldKey]
                                    ?.type === "SubmodelElementCollection"
                                ) {
                                  Component =
                                    componentMapping[
                                      "SubModelElementCollection"
                                    ];
                                } else {
                                  Component =
                                    componentMapping[
                                      SubmodelElementCollection?.[
                                        field?.fieldKey
                                      ]?.type
                                    ];
                                }
                              } else {
                                element = subModelElementsCollection.find(
                                  (el) => el.fieldKey === field.name
                                );
                                if (!element) return null;
                                Component = componentMapping[element?.type];
                              }

                              return (
                                <Component
                                  key={element.key}
                                  fields={[field]}
                                  languageCount={languageCount}
                                  referenceCount={referenceCount}
                                  relationCount={relationCount}
                                  form={props?.form}
                                  readonly={readonly && readonly}
                                  type={pagetype === "edit" && pagetype}
                                  submodelElementCollection={
                                    (pagetype === "edit" ||
                                      props?.readonly) &&
                                    SubmodelElementCollection?.[field?.fieldKey]
                                      ?.value
                                  }
                                  submodelFlag={
                                    (pagetype === "edit" ||
                                      props?.readonly) &&
                                    true
                                  }
                                  remove={(name: any) => {
                                    remove(name);
                                    setSubModelElementsCollection(
                                      (prevElements) =>
                                        prevElements.filter(
                                          (el) => el.key !== element.key
                                        )
                                    );
                                  }}
                                />
                              );
                            })}
                      </Card>
                    )}
                  </Form.List>
                ))
              }
          </Card>
        )
      )}
    </>
  );
};

export default SubmodelElementCollection;
