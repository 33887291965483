import { Modal, Button } from "antd";
import { Col, Form, Row, Table } from "react-bootstrap";

const SpecificAssetModal = (props: any) => {

  return (
    <Modal
      title="Specific Asset IDs"
      open={props?.open}
      onOk={() => props?.close()}
      onCancel={() => props?.close()}
      width={800}
      footer={false}
    >
      <Table bordered={true}>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        {props?.data?.map((item: any) => {
          return (
            <tbody>
              <tr>
                <td style={{ padding: 10 }}>{item?.name}</td>
                <td style={{ padding: 10 }}>{item?.value}</td>
              </tr>
            </tbody>
          );
        })}
      </Table>
    </Modal>
  );
};

export default SpecificAssetModal;
