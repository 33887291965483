import { Button, Card, message, Popconfirm, Select, Tooltip } from "antd";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsCopy } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";

const SubmodelDetails = (props: any) => {
  const [ language, setLanguage] = useState<string>(props?.details?.displayName[0]?.language)  
  const [ descriptionLanguage, setDescriptionLanguage] = useState<string>(props?.details?.description[0]?.language)  
  const handleLanguageChange = (value: string) => {
    setLanguage(value);
  };
  const handleDescriptionLanguage = (value:string) =>{
    setDescriptionLanguage(value)
  }
  return (
    <Card>
      <Col md={{ offset: 11, span: 1 }} className="d-flex justify-content-end">
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this submodal?"
          onConfirm={() => props?.onDelete(props?.details?.id)}
          onCancel={() => console.log("")}
          okText="Yes"
          cancelText="No"
        >
          {!props?.readonly ? (
            <Button danger className="SubmodelDetails_delete">
              Remove
              <RiDeleteBin6Line size={16} color="red" cursor={"pointer"} />
            </Button>
          ) : null}
        </Popconfirm>
      </Col>
      <Row>
        <Col sm={6} xs={12}>
          <div className="digitalTwinScreen-text4">
            {props?.details?.idShort}
          </div>
          <div className="digitalTwinScreen-text3">
            {props?.details?.id} &nbsp;&nbsp;&nbsp;
            <BsCopy
              color="green"
              size={20}
              cursor={"pointer"}
              onClick={() => {
                navigator.clipboard.writeText(props?.details?.id);
                message.success("ID copied");
              }}
            />
          </div>
          <div className="d-flex align-items-center digitalTwinScreen-text5">
            {props?.details?.description?.[0]?.text &&
              props?.details?.description?.find((item: any) => item.language === descriptionLanguage)?.text}&nbsp;&nbsp;&nbsp;
              {props?.details?.description?.length > 1 ? (
                <Select
                bordered={false}
                size="small"
                onChange={handleDescriptionLanguage}
                  options={props?.details?.description?.map((item: any) => ({
                    label: item.language === "en-us" ? "English" : "German",
                    value: item.language,
                  }))}
                  defaultValue={props?.details?.description[0]?.language}
                  style={{ width: "100px" }}
                />
              ) : null}
          </div>
        </Col>
        <Col sm={1} xs={12}></Col>
        <Col sm={5} xs={12}>
          {props?.details?.displayName[0]?.text ? (
            <div className="d-flex align-items-center digitalTwinScreen-text3">
              Display Name :{" "}
              {props?.details?.displayName?.find((item: any) => item.language === language)?.text}&nbsp;&nbsp;&nbsp;
              {props?.details?.displayName?.length > 1 ? (
                <Select
                bordered={false}
                size="small"
                onChange={handleLanguageChange}
                  options={props?.details?.displayName?.map((item: any) => ({
                    label: item.language === "en-us" ? "English" : "German",
                    value: item.language,
                  }))}
                  defaultValue={props?.details?.displayName[0]?.language}
                  style={{ width: "100px" }}
                />
              ) : null}
            </div>
          ) : null}
          {props?.details?.semanticId?.keys[0]?.value ? (
            <div className="digitalTwinScreen-text3">
              Semantic ID: {props?.details?.semanticId?.keys[0]?.value}{" "}
            </div>
          ) : null}
        </Col>
      </Row>
    </Card>
  );
};

export default SubmodelDetails;
