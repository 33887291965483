import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { HiMiniLanguage } from "react-icons/hi2";
import { IoMoonOutline, IoSearchOutline } from "react-icons/io5";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { IoMdMenu } from "react-icons/io";
import Logo from "../../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import Clock from "react-live-clock";
function Header(props: any) {
  const navigation: any = useNavigate();
  return (
    <div className="AuthHeader">
      <Container fluid>
        <Row>
          <Col sm={8} xs={8}>
            <div className="AuthHeader-Box">
              <div
                className="AuthHeader-Box3"
                style={{ marginLeft: -7 }}
                onClick={() => props?.showSider()}
              >
                <IoMdMenu color="#087ea4" size={30} />
              </div>
              <div className="AuthHeader-logo" style={{ marginLeft: 7 }}>
                <div>
                  <img
                    src={Logo}
                    onClick={() => navigation("/auth")}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  onClick={() => navigation("/auth")}
                  style={{ cursor: "pointer", marginLeft: 10 }}
                >
                  Digital Twin
                </div>
                <div className="AuthHeader-text1">v 1.0.0</div>
              </div>
            </div>
          </Col>
          <Col sm={4} xs={4}>
            <div className="AuthHeader-Box2">
              <div className="AuthHeader-Box4">
                <Clock format={"h:mm:ss A"} ticking={true} />
              </div>
              <div className="AuthHeader-Box3">
                <IoSearchOutline />
              </div>
              <div className="AuthHeader-Box3">
                <IoMoonOutline />
              </div>
              <div className="AuthHeader-Box3">
                <HiMiniLanguage />
              </div>

              <div className="AuthHeader-Box3">
                <HiOutlineUserCircle />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
