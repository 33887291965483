import "./App.scss";
import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import LoginScreen from "./website/loginScreen";
import ContactScreen from "./website/contactScreen";
import ProtectedRoute from "./utils/protectRoute";
import AppRoutes from "./app/navigation";

function App() {
  const User = useSelector((state: any) => state.auth);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "rgb(8 126 164)",
          fontFamily: "Inter-Regular",
          borderRadius: 4,
        },
        components: {
          Input: {
            colorTextPlaceholder: "grey",
            hoverBg: "#ebecf0",
            fontSize: 14,
            borderRadius: 2,
            fontSizeLG: 14,
          },
          Select: {
            fontSizeLG: 14,
          },
          Button: {
            fontFamily: "Inter-SemiBold",
          },
        },
      }}
    >
      <Routes>
        <Route index element={<LoginScreen />} />
        <Route path={"/"} element={<LoginScreen />} />
        <Route path={"/contact"} element={<ContactScreen />} />
        <Route
          path="/auth/*"
          element={
            <ProtectedRoute isSignedIn={User.auth}>
              <AppRoutes />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
