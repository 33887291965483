import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
interface Props {
  title: string;
  icon: ReactNode;
  description?: string;
  value?: number;
  color: string;
  navigate: string;
}
function Card2({ title, icon, description, value, color, navigate }: Props) {
  const navigation = useNavigate();
  return (
    <a href={navigate} target="__blank" className="dashboard-card1-cardHead">
      <div className="dashboard-card1-cardBox">
        <div className="dashboard-card1-title">{title}</div>
        <div className="dashboard-card1-iconHead">{icon}</div>
      </div>
      <div className="subFooterCardTxt">Launch Application</div>
    </a>
  );
}

export default Card2;
