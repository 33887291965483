import { Button, Card, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { IoIosRemoveCircleOutline } from "react-icons/io";

const ReferenceElement = (props: any) => {
  
  const findReferenceCount: any = () => {
    if (props?.readonly) {
      return props?.fields?.reduce((maxCount: number, { name }: any) => {
        const currentElementValue = props?.form?.getFieldValue([
          "Elements",
          name,
        ]);
        // const count = currentElementValue?.value?.length || 1;
        const count = 1
        return Math.max(maxCount, count);
      }, 1); 
    }
    return 1;
  };
  const readonly = props?.readonly ? props?.readonly : false;
  const [rows, setRows] = useState<any[][]>(
    props.fields.map(() =>
      Array(findReferenceCount() || 1)
        .fill(null)
        .map((_, index) => ({
          key: index,
          name: "",
          value: "",
        }))
    )
  );

  const addRow = (fieldIndex: number) => {
    setRows((prevRows) =>
      prevRows.map((row, index) =>
        index === fieldIndex
          ? [...row, { key: row.length, name: "", value: "" }]
          : row
      )
    );
  };

  const removeRow = (fieldIndex: number, rowIndex: number) => {
    setRows((prevRows) =>
      prevRows.map((row, index) =>
        index === fieldIndex ? row.filter((_, i) => i !== rowIndex) : row
      )
    );
    const currentValues = props?.form.getFieldsValue();
    currentValues.Elements[props?.fields[0]?.name].value.splice(rowIndex, 1);
    props?.form?.setFieldsValue({
      ...currentValues,
    });
  };
  return (
    <>
      {props?.fields?.map(
        ({ key, name, ...restField }: any, fieldIndex: any) => (
          <Card
            key={key}
            size="small"
            title={"Reference Element"}
            extra={
              !readonly && (
                <Button size="small" danger onClick={() => props?.remove(name)}>
                  Remove
                </Button>
              )
            }
            className="mb-4"
          >
            <Table key={key} bordered>
              <thead>
                <tr>
                  <th>ID Short</th>
                  <th>Display Name</th>
                  <th>Semantic ID</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "id_short"]}
                      rules={[
                        { 
                          // required: true,
                           message: "Please enter ID Short" },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter ID Short"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "display_name"]}
                      rules={[
                        {
                          // required: true,
                          message: "Please enter Display Name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter name"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "semantic_id"]}
                      rules={[
                        { 
                          // required: true,
                           message: "Please enter Semantic ID" },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter Semantic ID"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                  <td style={{ padding: 0 }}>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, "description"]}
                      rules={[
                        { 
                          // required: true,
                           message: "Please enter description" },
                      ]}
                    >
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter description"
                        style={{ padding: 10 }}
                      />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table bordered={true}>
              <thead>
                <tr>
                  <th>Value</th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {rows[fieldIndex].map((row, rowIndex) => (
                  <tr key={row.key}>
                    <td style={{ padding: 0 }}>
                      <Form.Item
                        noStyle
                        {...restField}
                        label="Value"
                        name={[name, "value", rowIndex]}
                        rules={[
                          { 
                            // required: true,
                             message: "Please enter value" },
                        ]}
                      >
                        <Input
                          size="large"
                          readOnly={readonly}
                          placeholder="Enter value"
                          style={{ padding: 10 }}
                        />
                      </Form.Item>
                    </td>
                    {/* <td style={{ width: 10 }}> */}
                      {/* {!readonly &&
                        (rowIndex === rows[fieldIndex].length - 1 ? (
                          <Button
                            size="small"
                            block
                            type="primary"
                            ghost
                            onClick={() => addRow(fieldIndex)}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            danger
                            onClick={() => removeRow(fieldIndex, rowIndex)}
                            size="small"
                          >
                            Remove
                          </Button>
                        ))} */}
                    {/* </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        )
      )}
    </>
  );
};

export default ReferenceElement;
