import { Button, Card, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Col, Row, Table } from "react-bootstrap";
import { IoIosRemoveCircleOutline } from "react-icons/io";

const File = (props: any) => {
  const form = props?.form.getFieldValue();
  const Data = form?.Elements;
  const readonly = props?.readonly ? props?.readonly : false;

  const fileValue = (name: any, topology: any) => {
    const formData = props?.form.getFieldValue();
    const item = formData?.Elements;

    if (!item || item.length === 0) {
      console.warn("Elements are undefined or empty");
      return "";
    }

    let resultValue = "";
    item.forEach((element: any, ind: any) => {
      const submodelCollection = element?.SubmodelElementCollection?.[0];
      if (submodelCollection) {
        const result = submodelCollection.find((subItem: any, index: any) => {
          if (subItem?.content_type && index === name?.[0]) {
            return subItem?.content_type;
          }
        });

        if (result) {
          resultValue = result?.value || "No value found";
        }
      }
      if (element?.content_type && ind === name?.[0] && topology === "Bus") {
        resultValue = element?.value;
      }
    });

    return resultValue;
  };

  return (
    <>
      {props?.fields?.map(({ key, name, ...restField }: any) => (
        <Card
          size="small"
          title={"File"}
          extra={
            !readonly && (
              <Button size="small" danger onClick={() => props?.remove(name)}>
                Remove
              </Button>
            )
          }
          className="mb-4"
        >
          <Table key={key} bordered>
            <thead>
              <tr>
                <th>ID Short</th>
                <th>Display Name</th>
                <th>Semantic ID</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "id_short"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter ID Short" },
                    ]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter ID Short"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "display_name"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter Display Name" },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter name"
                      readOnly={readonly}
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "semantic_id"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter Semantic ID" },
                    ]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter Semantic ID"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    name={[name, "description"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter description" },
                    ]}
                  >
                    <Input
                      readOnly={readonly}
                      size="large"
                      placeholder="Enter description"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered={true}>
            <thead>
              <tr>
                <th>Value</th>
                <th>Content Type</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    label="Value"
                    name={[name, "value"]}
                    rules={[{ 
                      // required: true,
                       message: "Please enter value" }]}
                  >
                    {readonly ? (
                      <a
                        href={
                          fileValue([name, "id_short"], props.topology) || "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ padding: 10, display: "inline-block" }}
                      >
                        {fileValue([name, "id_short"], props.topology) || ""}
                      </a>
                    ) : (
                      <Input
                        size="large"
                        readOnly={readonly}
                        placeholder="Enter value"
                        style={{ padding: 10 }}
                      />
                    )}
                  </Form.Item>
                </td>
                <td style={{ padding: 0 }}>
                  <Form.Item
                    noStyle
                    {...restField}
                    label="Content Type"
                    name={[name, "content_type"]}
                    rules={[
                      { 
                        // required: true,
                         message: "Please enter content type" },
                    ]}
                  >
                    <Input
                      size="large"
                      readOnly={readonly}
                      placeholder="Enter content type"
                      style={{ padding: 10 }}
                    />
                  </Form.Item>
                </td>
                {/* <td style={{ width: 10 }}>
                <Button>Add</Button>
              </td> */}
              </tr>
            </tbody>
          </Table>
        </Card>
      ))}
    </>
  );
};

export default File;
