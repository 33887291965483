import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { FiHelpCircle } from "react-icons/fi";
import { HiMiniLanguage } from "react-icons/hi2";
import { IoMoonOutline } from "react-icons/io5";
import Logo from "../../assets/images/logo.svg";
function Header() {
  return (
    <div className="Header">
      <Container fluid>
        <Row>
          <Col sm={8} xs={8}>
            <div className="Header-Box1">
              <div className="Header-logo">
                <div>
                  <img src={Logo} />
                </div>
                <div>Digital Twin</div>
              </div>
            </div>
          </Col>
          <Col sm={4} xs={4}>
            <div className="Header-Box2">
              <div className="Header-Box4">Contact Us</div>
              <div className="Header-Box3">
                <IoMoonOutline />
              </div>
              <div className="Header-Box3">
                <HiMiniLanguage />
              </div>
              <div className="Header-Box3">
                <FiHelpCircle />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
