import { Card } from "antd";
import Property from "../elements/property";
import MultiLanguageProperty from "../elements/multiLanguageProperty";
import ReferenceElement from "../elements/referenceElement";
import RelationshipElement from "../elements/relationshipElement";
import File from "../elements/file";
import Range from "../elements/range";
import SubmodelElementCollection from "../elements/submodelElementCollection";

const componentMapping: any = {
  Property: Property,
  MultiLanguageProperty: MultiLanguageProperty,
  File: File,
  Range: Range,
  ReferenceElement: ReferenceElement,
  RelationshipElement: RelationshipElement,
  SubmodelElementCollection: SubmodelElementCollection,
  // AnnotatedRelationshipElement: AnnotatedRelationshipElement,
  // Entity: Entity,
};

const SubmodelElementsDetails = (props: any) => {
  localStorage.setItem("count", JSON.stringify(0));
  const getData = (data: any) => {
    const submodelElementCollection = data?.submodelElements?.filter(
      (subElement: any) => subElement?.modelType === "SubmodelElementCollection"
    );
    if (submodelElementCollection?.length) {
      let count: any = localStorage.getItem("count");
      count = JSON.parse(count);
      count = Number(count);
      if (count < submodelElementCollection?.length) {
        const collection =
          submodelElementCollection?.[count]?.value &&
          mapSubElementsRecursive(submodelElementCollection?.[count]?.value);
        count = count + 1;
        localStorage.setItem("count", JSON.stringify(count));
        return collection;
      }
    }
  };

  const mapSubElementsRecursive = (elements: any[], parentIndex = 0): any[] => {
    if (elements.length === 1 && elements[0] === null) {
      return []; 
    }
    return elements.map((subElement: any, index: number) => {
      const mappedElement = {
        type: subElement?.modelType,
        key: Date.now() + parentIndex + index,
        fieldKey: index,
      };

      if (subElement?.submodelElements) {
        return {
          ...mappedElement,
          submodelElements: mapSubElementsRecursive(
            subElement?.submodelElements,
            index
          ),
        };
      }

      if (subElement?.value && Array.isArray(subElement?.value)) {
        return {
          ...mappedElement,
          value: mapSubElementsRecursive(subElement?.value, index),
        };
      }

      return mappedElement;
    });
  };
  return (
    <Card title={"Submodel Elements"}>
      {props?.fields.map((field: any, index: any) => {
        const element = props?.subModelElements.find(
          (el: any) => el.fieldKey === field.name
        );
        if (!element) return null;
        const Component = componentMapping[element.type];
        return (
          <>
            {props?.subModelElements.length && (
              <Component
                key={element.key}
                fields={[field]}
                languageCount={props?.languageCount}
                referenceCount={props?.referenceCount}
                relationCount={props?.relationCount}
                form={props?.form}
                readonly={true}
                topology={"Bus"}
                submodelElementCollection={
                  props?.mappedSubmodelElementCollection
                }
                sub={ element?.type ==="SubmodelElementCollection" && getData(props?.data)}
              />
            )}
          </>
        );
      })}
    </Card>
  );
};

export default SubmodelElementsDetails;
