import SubmodelElementCollection from "./submodelElementCollection";

const Sub = (props: any) => {
  return (
    <SubmodelElementCollection
      key={props?.key}
      fields={props?.fields}
      form={props?.form}
      remove={props?.remove}
      readonly={props?.readonly&&props?.readonly}
      type = {props?.type === "edit" && props?.type}
      submodelElementCollection = {(props?.type === "edit" || props?.readonly) && props?.submodelElementCollection}
      submodelFlag = {(props?.type === "edit" || props?.readonly) && props?.submodelFlag}
      // count = {props?.type === "edit" && props?.count}
    />
  );
};
export default Sub;
