const API = {
  SUBMODAL_URL: "https://subm-api.bairuhatech.com/",
  DIGITALTWIN_URL: "https://aas-api.bairuhatech.com/",
  // SUBMODAL_URL: "https://subm-api-dev.bairuhatech.com/", //development
  // DIGITALTWIN_URL: "https://aas-api-dev.bairuhatech.com/", //development

  GET_SUBMODELS: "submodels", //GET
  ADD_SUBMODELS: "submodels", // POST

  GET_TWIN: "shells", //GET
  ADD_TWIN: "shells", //POST
};
export default API;
