import "./styles.scss";
import { Button, Input } from "antd";
import { IoRefreshOutline } from "react-icons/io5";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/pageHeader";
import DataTable from "./components/dataTable";

function SubModelTemplates() {
  const navigate = useNavigate();
  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Submodel Templates ( 275 )"}
          breadcum={"Dashboard / Submodel Templates"}
        >
          <div>
            <Input placeholder="Search" />
          </div>
          <div>
            <Button>
              <IoRefreshOutline size={17} />
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => navigate("/auth/SubModelTemplateFrom/0")}
            >
              New
            </Button>
          </div>
        </PageHeader>
        <DataTable />
      </Container>
    </main>
  );
}

export default SubModelTemplates;
