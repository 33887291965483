import { Button, Form, Input, Modal, Select } from "antd";
import { Table } from "react-bootstrap";

const DisplayNameModal = (props: any) => {
  return (
    <Modal
      title="Display Name"
      open={props?.visible}
      width={700}
      onCancel={() => props?.onClose()}
      onOk={() => props?.onClose()}
    >
      <Table bordered>
        <thead>
          <tr>
            <th>Language</th>
            <th>Display Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props?.fields.map(({ key, name, ...restField }: any, index: any) => (
            <tr key={index}>
              <td style={{ padding: 0 }}>
                <Form.Item
                  noStyle
                  {...restField}
                  initialValue={"en-us"}
                  name={[name, "language"]}
                >
                  <Select
                    bordered={false}
                    style={{ width: "100%" }}
                    size="large"
                    options={[
                      { value: "en-us", label: "English" },
                      { value: "de", label: "German" },
                    ]}
                  />
                </Form.Item>
              </td>
              <td style={{ padding: 0 }}>
                <Form.Item noStyle {...restField} name={[name, "display_name"]}>
                  <Input
                    bordered={false}
                    size="large"
                    placeholder="Enter Display Name"
                  />
                </Form.Item>
              </td>
              <td style={{ textAlign: "center", width: 50 }}>
                {index === props?.fields.length - 1 ? (
                  <Button
                    type="primary"
                    ghost
                    block
                    size="small"
                    onClick={props?.add}
                  >
                    Add +
                  </Button>
                ) : (
                  <Button
                    size="small"
                    danger
                    block
                    onClick={() => props.remove(index)}
                  >
                    Remove
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
};

export default DisplayNameModal;
