import { Modal, Table, Button } from "antd";
import { Col, Row } from "react-bootstrap";

import Tables from "../data/index";

const ExportModal = (props: any) => {
  const dataSource = [
    {
      key: "1",
      name: "Ship 1234",
      value: "Digital Twin",
      type: "ship",
    },
    {
      key: "2",
      name: "Ship 42345",
      value: "Digital Twin",
      type: "ship",
    },
  ];

  const columns = [
    {
      title: "Display Name / ID Short",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Description",
      dataIndex: "",
      key: "",
    },
  ];

  return (
    <Modal
      title="Export Passport"
      open={props?.open}
      onOk={() => props?.close()}
      onCancel={() => props?.close()}
      width={800}
      footer={false}
    >
      <br />
      <Table
        size="small"
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <Tables />,
        }}
      />
      <br />
      <Row>
        <Col sm={8} xs={12}></Col>
        <Col sm={2} xs={12}>
          <Button block type="primary" onClick={() => props?.close()}>
            Publish
          </Button>
        </Col>
        <Col sm={2} xs={12}>
          <Button block danger onClick={() => props?.close()}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ExportModal;
