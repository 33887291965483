import { message, Table } from "antd";
import { BsCopy } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
function DataTable() {
  const navigate = useNavigate();
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "1",
      name: "Mike",
      age: 32,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "1",
      name: "Mike",
      age: 32,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "1",
      name: "Mike",
      age: 32,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
    {
      key: "2",
      name: "John",
      age: 42,
    },
  ];

  const detailScreen = (id: any) => {
    navigate("/auth/DgTwinDetails/1");
  };

  const columns = [
    {
      title: "Identification",
      width: 850,
      render: (text: any) => (
        <div>
          <div className="subModelTemplates-text1">Ship 5868</div>
          <div className="subModelTemplates-text2">
            This is where the description of the Digital Twin should be
          </div>
        </div>
      ),
    },
    {
      title: "Semantic ID",
      dataIndex: "age",
      key: "age",
      render: (text: any) => (
        <div>
          <div>
            58678234345 &nbsp;{" "}
            <BsCopy
              color="green"
              size={15}
              onClick={() => message.success("ID copied")}
            />
          </div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "age",
      key: "age",
      width: 30,
      render: () => (
        <div>
          <IoIosArrowForward size={20} />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table size="small" dataSource={dataSource} columns={columns} />
    </div>
  );
}

export default DataTable;
