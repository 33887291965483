import Cookies from "js-cookie"; // Ensure you have imported js-cookie

const GET = async (url: any, params?: any) => {
  return new Promise(async (resolve, reject) => {

    // Retrieve the authToken from the cookie
    const authToken = Cookies.get("authToken");

    // Check if authToken exists
    if (!authToken) {
      reject(new Error("Authorization token is missing"));
      return;
    }

    fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Basic ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    // Retrieve the authToken from the cookie
    const authToken = Cookies.get("authToken");

    // Check if authToken exists
    if (!authToken) {
      reject(new Error("Authorization token is missing"));
      return;
    }
    
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `Basic ${authToken}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

 const PUT = async (url: string, body: any) => {
  try {
    const response = await fetch(url, {
      method: "PUT", 
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Basic YWRtaW46MTIz",
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const text = await response.text();

    if (!text) {
      console.warn("Received an empty response from the API.");
      return null; 
    }
    return JSON.parse(text);

  } catch (error) {
    console.error("Error during PUT request:", error);
    throw error;
  }
};

const DELETE = async (url: any) => {
  try {
    const response = await fetch (url,{
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Basic YWRtaW46MTIz",
      },
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const text = await response.text();

    if (!text) {
      console.warn("Received an empty response from the API.");
      return null; 
    }
    return JSON.parse(text);
  } catch (error) {
    console.error("Error during PUT request:", error);
    throw error;
  }
};

export { GET, POST, PUT, DELETE };
