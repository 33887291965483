import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";

import Header from "../components/header";
import SideBar from "../components/sideBar";

import DashBoard from "../screens/dashboardScreen";

import DigitalTwinScreen from "../screens/digitalTwinScreen";
import DigitalTwinDetails from "../screens/digitalTwinScreen/details";
import DigitalTwinForm from "../screens/digitalTwinScreen/create";

import SubModelScreen from "../screens/subModalScreen";

import SubModelTemplates from "../screens/subModelTemplates";
import SubModelTemplateFrom from "../screens/subModelTemplates/create";

import DigitalTwinTemplates from "../screens/digitalTwinTemplates";
import DgTwinTemplateFrom from "../screens/digitalTwinTemplates/create";
import SubModelScreenFrom from "../screens/subModalScreen/create";
import SubModelsDetails from "../screens/subModalScreen/details";

function AppRoutes() {
  const [siderShow, setSiderShow] = useState(true);
  return (
    <Layout>
      {siderShow ? (
        <Layout.Sider
          style={{
            backgroundColor: "#fff",
            height: "100vh",
            borderRight: "1px solid rgb(241, 241, 241)",
          }}
          width={65}
        >
          <SideBar />
        </Layout.Sider>
      ) : null}
      <Header showSider={() => setSiderShow(!siderShow)} />
      <Layout.Content
        style={{
          background: "#f3f3f3",
          padding: "10px",
          paddingTop: "8vh",
          height: "100vh",
          overflow: "scroll",
        }}
      >
        <Routes>
          <Route path="/" element={<DashBoard />} />

          <Route path="/dgTwin" element={<DigitalTwinScreen />} />
          <Route path="/DgTwinDetails/:id" element={<DigitalTwinDetails />} />
          <Route path="/DgTwinForm/:id" element={<DigitalTwinForm />} />

          <Route path="/SubModelScreen" element={<SubModelScreen />} />
          <Route path="/SubModelDetails/:id" element={<SubModelsDetails />} />

          <Route path="/SubModelTemplates" element={<SubModelTemplates />} />
          <Route
            path="/SubModelTemplateFrom/:id"
            element={<SubModelTemplateFrom />}
          />
          <Route
            path="/SubModelScreenFrom/:id"
            element={<SubModelScreenFrom />}
          />

          <Route path="/dgTwinTemplates" element={<DigitalTwinTemplates />} />
          <Route path="/DgTwinTempFrom/:id" element={<DgTwinTemplateFrom />} />
        </Routes>
      </Layout.Content>
    </Layout>
  );
}

export default AppRoutes;
